.sidebar-wrapper {
	@apply overflow-y-auto  w-[400px] z-[2000] absolute top-0 h-full bg-theme-800 border-theme-600 px-3 py-3 flex flex-col;
	@apply overflow-x-hidden;

	&.right {
		@apply right-0 border-l;
	}
	&.left {
		@apply left-0 border-r;
	}
	&::-webkit-scrollbar {
		@apply w-2;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		@apply bg-black/20;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		@apply bg-black/20 rounded-full;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		@apply bg-black/30;
	}

	&.always-scroll {
		@apply overflow-y-scroll;
		mask-image: linear-gradient(to top, transparent, black),
			linear-gradient(to left, transparent 8px, black 8px);
		mask-size: 100% 20000px;
		mask-position: left bottom;
		-webkit-mask-image: linear-gradient(to top, transparent, black),
			linear-gradient(to left, transparent 8px, black 8px);
		-webkit-mask-size: 100% 20000px;
		-webkit-mask-position: left bottom;
		transition: mask-position 0.3s, -webkit-mask-position 0.3s;

		&:hover {
			-webkit-mask-position: left top;
		}
	}
}
