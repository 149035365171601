.sidebar-tabs {
	&.sticky {
		.sidebar-tabs-list {
			position: sticky;
			// background-color: black;
			top: -12px;
			z-index: 999999;
			@apply -mx-4 -mt-4 px-4 py-2 bg-theme-800 shadow-xl border-b-2 border-b-black/30;
		}
		.sidebar-tabs-list-placeholder {
			// height: 39px;
		}
	}
}

.sidebar-tabs-list {
	@apply -mx-0.5 flex text-white text-center;

	.tab-item {
		@apply transition-colors px-2 pt-1.5 pb-2 bg-black/20 text-center mx-0.5 flex-1 rounded-sm border-b border-b-transparent;
		&.selected {
			@apply border-b-lime-500 bg-black/30;
		}
		svg {
			@apply inline-block h-5 w-5;
		}
	}
}

.sidebar-tabs-panels {
	.tab-panel {
		@apply py-3 flex flex-col;
	}
}
